<template>
  <div class="release">
    <div class="content">
      <div class="header">
        <router-link
          class="logoLink"
          tag="a"
          to="/">
          <treee-logo-with-mark class="logo"/>
        </router-link>
      </div>
      <h1 class="title">Release note</h1>
      <div class="releaseNotes">
        <section class="releaseNote" v-for="item in releaseNotes" :key="item.id">
          <b class="releaseDate">{{ formatDate(new Date(item.releaseDate)) }}</b>
          <span class="versionName">[{{ item.version }}]</span>
          <p class="releaseNoteBody" v-html="item.content" />
        </section>
      </div>
      <div class="releaseNotePager">
        <flat-button
          v-if="totalCount > releaseNotes.length"
          @click="loadNext"
        >load next</flat-button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import dayjs from 'dayjs'
import TreeeLogoWithMark from '../images/TreeeLogoWithMark.svg'
import FlatButton from '../components/common/atoms/FlatButton'

const releaseNotesUrl = new URL('https://treee.microcms.io/api/v1/release')
const ops = {
  headers: {
    'X-API-KEY': '95c4908e-1f4f-4c3c-ad3b-c546d9fba7d4'
  }
}
const limit = '5'

export default Vue.extend({
  name: 'Release',
  components: {
    TreeeLogoWithMark,
    FlatButton
  },
  data () {
    return {
      releaseNotes: [],
      totalCount: 0
    }
  },
  async mounted () {
    const q = new URLSearchParams()
    q.set('limit', limit)
    q.set('offset', '0')
    releaseNotesUrl.search = q.toString()
    const releaseNotes = await fetch(releaseNotesUrl, ops)
    const data = await releaseNotes.json()
    this.releaseNotes = data.contents
    this.totalCount = data.totalCount
  },
  methods: {
    formatDate (date) {
      return dayjs(date).format('YYYY-MM-DD')
    },
    async loadNext () {
      const q = new URLSearchParams()
      q.set('limit', limit)
      q.set('offset', this.releaseNotes.length)
      releaseNotesUrl.search = q.toString()
      const releaseNotes = await fetch(releaseNotesUrl, ops)
      const data = await releaseNotes.json()
      this.releaseNotes = this.releaseNotes.concat(data.contents)
      this.totalCount = data.totalCount
    }
  }
})
</script>

<style scoped lang="scss">
  @import "../styles/constants.scss";

  .content {
    width: 600px;
    margin: 0 auto;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 64px;
    margin: 0 0 20px;
  }

  .logo {
    height: 40px;
  }

  .title {
    @extend %typo-h2;

    margin: 0 0 30px;
    color: $primary-d;
  }

  .releaseNotes {
    margin: 0 0 60px;
    padding: 0;
  }

  .releaseNote {
    margin: 0 0 120px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 20px;
  }

  .releaseNote:last-child {
    margin: 0;
  }

  .releaseDate {
    @extend %typo-h3;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 40px;
    color: $primary-d;
  }

  .releaseNoteBody {
    grid-column-start: 1;
    grid-column-end: 3;
    margin: 0 0 20px;
  }

  .releaseNoteBody /deep/ p {
    @extend %typo-body2;

    margin: 0 0 20px;
    color: $primary-d;
  }

  .releaseNoteBody /deep/ li {
    @extend %typo-body2;

    color: $primary-d;
  }

  .releaseNoteBody /deep/ p:last-child {
    margin: 0;
  }

  .versionName {
    @extend %typo-h4;

    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: $low-d;
  }

  .releaseNotePager {
    text-align: center;
    margin: 0 0 60px;
  }
</style>
